import React , { useState }  from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/contact.sass"
import "../assets/wave.sass"
import ReCAPTCHA from "react-google-recaptcha";
import validateEmail from '../lib/validateEmail'
import firebase from "../components/firebase"

const Contact = () => {

  const initialMessageInfoState = { email: "", city: "", phoneNumber: "", name: "", message: ""}
  const [messageInfo, setMessageInfo] = useState(initialMessageInfoState);

  const initialReCAPTCHAState = {
    human: false,
    disabled: true
  }

  const sendMessage = () => {
    
  }
  
  const onChange = (value) => {
    console.log("Captcha value:", value);
  }
  
  return(
    <div className="contact-us-component">

      <SEO title="Contact" />

      <h1>Get in touch with us</h1>
      <p>Our team is ready to help.</p>
      <div className="contact-info">
        <div className="contact-image-wrapper">
          <a href="mailto:thex.signaturecompany@gmail.com">
            <Image filename="email.png" />
            thex.signaturecompany@gmail.com
          </a>
        </div>
        <div className="contact-image-wrapper">
          <Image filename="phone.png" />
          <a href="tel:630-544-1126">(630)-544-1126</a>
        </div>
        {/* <div className="contact-image-wrapper">
          <Image filename="messenger.png" />      
          <a href="https://m.me/tonysdrainsewercleaning">Messenger</a>
        </div> */}
      </div>
{/* 
      <div className="form-container">
        <form onSubmit={sendMessage} className="form-contact-page">
          <input maxLength="45" className="input-field" type="email" name="email" placeholder="Email" onChange={e => setMessageInfo({...messageInfo,email:e.currentTarget.value})}/>
          <input maxLength="45" className="input-field" type="text" name="phoneNumber" placeholder="Phone Number" onChange={e => setMessageInfo({...messageInfo,phoneNumber:e.currentTarget.value})}/>
          <input maxLength="45" className="input-field" type="text" name="city" placeholder="City" onChange={e => setMessageInfo({...messageInfo,city:e.currentTarget.value})}/>
          <input maxLength="20" className="input-field" type="text" name="name" placeholder="Name" onChange={e => setMessageInfo({...messageInfo,name:e.currentTarget.value})}/>
          <textarea  maxLength="250" className="input-field message" placeholder="Message" name="message" cols="30" rows="10" onChange={e => setMessageInfo({...messageInfo,message:e.currentTarget.value})}/>
          <ReCAPTCHA className="captcha" sitekey="6LdXLXYaAAAAAP6lEqhnCa_HHBX6BrRz2I_GX69V"  />
          <button className="submit-button">Send Message</button>
        </form>
      </div> */}

      <div className="tonys-location">
        <div className="buisness-service-locations">
          <p>We're in Aurora IL.</p>
          <p>Servicing the entire Chicagoland area</p>
          <p>Locations we service</p>
          <p>dont see your city? give us call and we can arrange something.</p>
          <div>
            St Charles
          </div>
          <div>
            Batavia
          </div>
          <div>
            Joliet
          </div>
          <div>
            Elgin
          </div>
          <div>
            Geneva
          </div>
          <div>
            Yorkville
          </div>
          <div>
            Naperville
          </div>
          <div>
            North Aurora
          </div>
          <div>
            Boolingbrook
          </div>
          <div>
            Oswego
          </div>
          <div>
            Sugar Grove
          </div>
          <div>
            Montgomery
          </div>
          <div>
            Downers Grove
          </div>
          <div>
            Chicagoland Area
          </div>
        </div>
      </div>

    </div>
  )
}
export default Contact